import React from 'react';

import "./FlexText.css";

export default function FlexText(r)
{
    switch(r.text.type)
    {
        case "p":
            return(
                <>
                    {r.text.p.map((p) => (
                        <p>{p}</p>
                    ))}
                </>
            );
        case "l":
            return(
                <>
                    <ul>
                        {r.text.l.map((l) => (
                            <li>{l}</li>
                        ))}
                    </ul>
                </>
            )
        default:
            return(
                <>
                    {r.text}
                </>
            );
    }
}