import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import './Typewriter.css'

function Typewriter() {
    const el = useRef(null);
    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['<h2>Greetings, Adventurers!</h2> <br />' + 
                        '<h4>Welcome to the archive of the Orc scribe, Balator. This is a place where tales of valor and victory are recorded for all to see. Here, you will find the stories of heroes and monsters, of magic and intrigue.</h4>' + 
                        '<h4>Step inside and discover the secrets of the ages. Explore the annals of history and the chronicles of legend. From the frozen tundras of the north to the scorching deserts of the south, these pages hold the tales of a thousand worlds.</h4>' +
                        '<h4>So come, dear visitor, and join me on this journey through the ages. Let us delve into the mysteries of the past, and unlock the secrets of the present.</h4> ' +
                        '<h4>The pen is in my hand, and the world is at our feet. Let us write the next great adventure together.</h4>'],
          loop: false,
          startDelay: 300,
          typeSpeed: 75,
          showCursor: false
        });
        return () => {
          typed.destroy();
        };
      }, []);

      return(
        <>
            <div className="floating-text" ref={el}/>
        </>
      )
}

export default Typewriter;