import React, { useState, useEffect } from 'react';
import axios from "axios";

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import InfoDump from './InfoDump.js';

import './Avatargrid.css';

export default function Avatargrid(r){
    const [selectedItem, setSelectedItem] = useState();

    const [isInfo, setIsInfo] = useState(false);
    const [items, setItems] = useState([]);

    async function getItems(){
        const response = await axios.get(r.path);
        setItems(response.data.index);
    };

    function showInfoDump(item)
    {
      setSelectedItem(item);
      setIsInfo(true);
    }

    useEffect(() => {
      getItems();
    }, []);

    return(
      <>
      <Container fixed sx={{ padding:0, margin:0, display:'flex', justifyContent:'center' }}>
        <Box sx={{ height: '80vh',
                  display:"flex", 
                  justifyContent:'center', 
                  alignItems:'baseline',
                  position: 'absolute',
                  top: '50%',
                  backgroundColor: '#c7c7c7d9',
                  borderRadius: '10px',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  padding: '10px', 
                  width: '60vw'}}>

          {isInfo ? 
          <div>
            <IconButton
                      sx={{ 
                        color: 'rgba(255, 255, 255, 0.54)',
                        "&:hover": { 
                          color: '#fff',
                          transform: 'scale(1.2)' 
                        }
                      }}
                    >
                      <KeyboardBackspaceIcon onMouseDown={(event) => {
                        if (event.button === 0) {
                          // window.open('/#/info/id=' + item.id, '_blank', 'active=no')
                          setIsInfo(false)
                        }}}/>
                    </IconButton>
            {/* <Button onClick={() => setIsInfo(false)}>Back</Button> */}
            <InfoDump data={selectedItem}></InfoDump> 
          </div>         
          :
          <ImageList cols={6} gap={10}>
            {items.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.subTitle}
                  actionIcon={
                    <IconButton
                      sx={{ 
                        color: 'rgba(255, 255, 255, 0.54)',
                        "&:hover": { 
                          color: '#fff',
                          transform: 'scale(1.2)' 
                        }
                      }}
                    >
                      <InfoIcon onMouseDown={(event) => {
                        if (event.button === 0) {
                          // window.open('/#/info/id=' + item.id, '_blank', 'active=no')
                          showInfoDump(item);
                        }}}/>
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          }        
          </Box>
        </Container>
      </>
    )
}