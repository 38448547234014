import React from 'react';
import Backdrop from '../components/Backdrop'
import Typewriter from '../components/Typewriter';

import './Home.css'

function Home (){
    return (
        <>
          <Typewriter></Typewriter>
          <Backdrop path='./resources/images/bg/home'> </Backdrop>
        </>
    )
}

export default Home;