import {
  Routes,
  Route,
  HashRouter 
} from "react-router-dom";

import './App.css'
import Navbar from "./components/Navbar";

import Home from "./pages/Home";
import Characters from "./pages/Characters";

function App() {
  return (
    <HashRouter >
        <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/characters" element={<Characters />}/>
      </Routes>
    </HashRouter >
  );
}

export default App;