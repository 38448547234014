import React from 'react';

import Backdrop from '../components/Backdrop'

import './Characters.css';
import Avatargrid from '../components/Avatargrid';

function Characters() {
    return(
      <>
        <Backdrop path='./resources/images/bg/chars'> </Backdrop>
        <Avatargrid path='./resources/data/chars_index.json'></Avatargrid>
      </>
    )
}

export default Characters;