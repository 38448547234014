import React, { useState, useEffect } from 'react';
import axios from "axios";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FlexText from './FlexText';

import './InfoDump.css';

export default function InfoDump(r){
    const [data, setData] = useState({});
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    async function getData(){
        const response = await axios.get('./resources/data/'+ r.data.type +'_data.json');
        setData(response.data.data.find(item => item.info.id === r.data.id));
    };

    useEffect(() => {
        getData();
    }, [r.data.id]);

    return(
        <>
            {Object.keys(data).length !== 0 ?
                data.sections.map((section) => (
                        <Accordion expanded={expanded === section.title} onChange={handleChange(section.title)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {section.title}
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary' }}>{section.text.substring(0, 50)}...</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography padding='0 2em'>
                                <FlexText text={section.text}/>
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                ))
                : null }
        </>
    )
}