import React from 'react';
import {Link} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import './Navbar.css';

function handleClick(event) {
  event.preventDefault();
}

function Navbar() {
  if (!window.location.hash.includes("#/info")) {     
    return (
      <>
        <nav className="navbar" >
          <Link to="/">
            Home
          </Link>
          <Link to="/characters">
          Characters
          </Link>
          <Tooltip title="Working on it">
            <Link className='disabled' data-tip="true" to="/Creatures" onClick={handleClick}>
              Creatures
            </Link>
          </Tooltip>
          <Tooltip title="Working on it">
          <Link className='disabled' to="/Places" onClick={handleClick}>
          Places
          </Link>
          </Tooltip>
          <Tooltip title="Working on it">
          <Link className='disabled' to="/Magic" onClick={handleClick}>
          Magic
          </Link>
          </Tooltip>
          <Tooltip title="Working on it">
          <Link className='disabled' to="/Adventures" onClick={handleClick}>
          Adventures
          </Link>
          </Tooltip>
        </nav>
      </>
    );
    }else {
    return null;
  }
}

export default Navbar;