import React, { useState, useEffect } from 'react';
import axios from "axios";
import './Backdrop.css';
import defaultImage from '../default.png';

function Backdrop(r) {
    const img = new Image();
    img.src = defaultImage;

    const [imagePaths, setImagePaths] = useState([defaultImage]);      
    const [currentImageIndex, setCurrentImageIndex] = useState(0);    
    const [images, setImages] = useState([]);

    async function getImagePaths(){
            const response = await axios.get(r.path);
            const preloadedImages = imagePaths.map((imagePath) => {
                const img = new Image();
                img.src = imagePath;
                return img;
            });
            setImagePaths(response.data.map(img => r.path + '/' + img ));      
    }

    useEffect(() => {   
        getImagePaths();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentImageIndex((currentImageIndex + 1) % imagePaths.length);
        }, 60000);
        return () => clearInterval(interval);
      }, [currentImageIndex, imagePaths.length]);
    
    return (
        <>
        <div
            className="backdrop"
            style={{
                backgroundImage: `url(${imagePaths[currentImageIndex]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top, center',
            }}
            >
        </div>
        </>
    )
}

export default Backdrop;